<template>
  <div v-if="!isFinished" ref="lottieContainer" class="lottie-animation">
    <div ref="lottieAnimation" class="lottie-animation__content"></div>
  </div>
</template>

<script>
export default {
  name: 'LottieAnimation',
}
</script>

<script setup>
import { gsap } from 'gsap'
import lottie from 'lottie-web'

import { useStore } from '@/store/index.js'
import animationData from '/public/animation/suntimes.json'

const store = useStore()

const lottieContainer = ref(null)
const lottieAnimation = ref(null)
const animation = ref(null)
const isFinished = ref(false)

onMounted(() => {
  window.lenis.scrollTo(0, { immediate: true })

  animation.value = lottie.loadAnimation({
    container: lottieAnimation.value,
    renderer: 'svg',
    loop: false,
    autoplay: true,
    animationData: animationData,
  })

  animation.value.addEventListener('complete', () => {
    // gsap.to(lottieAnimation.value, { opacity: 0, duration: 0.4 })
    gsap.to(lottieContainer.value, {
      opacity: 0,
      duration: 0.4,
      onComplete: () => {
        isFinished.value = true
        store.setLottie(true)
      },
    })
  })
})
onUnmounted(() => {
  animation.value.destroy()
})
</script>

<style scoped lang="scss">
@import './style.scss';
</style>
