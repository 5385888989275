<template>
  <WidgetOverlayFullScreen
    class="full-screen-video-overlay"
    ref="blockOverlay"
    v-if="item"
  >
    <!-- Close Button -->
    <button
      ref="magnetic"
      class="full-screen-video-overlay__close"
      @click="animateOut"
    >
      <svg
        class="close-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="47"
        viewBox="0 0 48 47"
      >
        <g id="Arrow_Left" transform="translate(-938.465 -3430.28)">
          <g
            id="Rettangolo_93"
            data-name="Rettangolo 93"
            transform="translate(938.465 3430.28)"
            fill="none"
            stroke="#f7f1e9"
            stroke-width="2"
          >
            <rect width="48" height="47" rx="23.5" stroke="none" />
            <rect x="1" y="1" width="46" height="45" rx="22.5" fill="none" />
          </g>
          <path
            id="Unione_23"
            data-name="Unione 23"
            d="M19.856,21.739,11.013,12.9,2.172,21.738a1.272,1.272,0,0,1-1.8-1.8l8.884-8.884L.372,2.171a1.272,1.272,0,0,1,1.8-1.8l8.842,8.843L19.856.374a1.272,1.272,0,0,1,1.8,1.8l-8.884,8.884,8.884,8.884a1.272,1.272,0,1,1-1.8,1.8Z"
            transform="translate(951.692 3442.507)"
            fill="#f7f1e9"
          />
        </g>
      </svg>
    </button>
    <div class="full-screen-video-overlay__content">
      <!-- Video -->
      <WidgetVideo
        ref="blockOverlayVideo"
        class="full-screen-video-overlay__video is-cover"
        :videoUrl="item"
        :controls="true"
        :loop="false"
        :autoplay="false"
        :muted="false"
        @videoEnded="animateOut"
      />
      <div class="rotate-device-image">
        <img
          src="@/assets/img/ico/ico-rotate-white.svg"
          width="58"
          height="55"
          :alt="$t('cta.rotateDevice')"
        />
      </div>
    </div>
  </WidgetOverlayFullScreen>
</template>

<script>
export default {
  name: 'WidgetFullScreenVideoOverlay',
}
</script>

<script setup>
import { gsap } from 'gsap'

const item = useState('quickview', () => null)

const blockOverlay = ref(null)
const blockOverlayVideo = ref(null)
const magnetic = ref(null)
const durationSlow = 1

const xTo = ref(null)
const yTo = ref(null)

watch(
  () => item.value,
  async (item) => {
    if (item) {
      await nextTick()
      await animateIn()
      await nextTick()
      // addMagneticEffect()
    }
  }
)

const animateIn = async () => {
  await blockOverlay.value.animateIn().duration(durationSlow).play()
  blockOverlayVideo.value.videoElement.play()
}

const animateOut = async () => {
  blockOverlayVideo.value.videoElement.pause()
  await blockOverlay.value.animateOut().duration(durationSlow).play()
  item.value = null
}

const handleMouseMove = (e) => {
  const rect = magnetic.value.getBoundingClientRect()
  const offsetX = e.clientX - (rect.left + rect.width / 2)
  const offsetY = e.clientY - (rect.top + rect.height / 2)
  const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2)

  if (distance < 200) {
    // Adjust this value to control the distance at which the magnetic effect starts
    const x = offsetX * 0.3
    const y = offsetY * 0.3
    xTo.value(x)
    yTo.value(y)
  } else {
    xTo.value(0)
    yTo.value(0)
  }
}

const handleMouseLeave = () => {
  xTo.value(0)
  yTo.value(0)
}

const addMagneticEffect = () => {
  if (!magnetic.value) return

  xTo.value = gsap.quickTo(magnetic.value, 'x', {
    duration: 1,
    ease: 'elastic.out(1, 0.3)',
  })
  yTo.value = gsap.quickTo(magnetic.value, 'y', {
    duration: 1,
    ease: 'elastic.out(1, 0.3)',
  })

  document.addEventListener('mousemove', handleMouseMove)
  magnetic.value.addEventListener('mouseleave', handleMouseLeave)
}

onMounted(async () => {
  await nextTick()
  // addMagneticEffect()
})

onUnmounted(() => {
  if (magnetic.value) {
    document.removeEventListener('mousemove', handleMouseMove)
    magnetic.value.removeEventListener('mouseleave', handleMouseLeave)
  }
})
</script>

<style lang="scss">
@import './style.scss';
</style>
