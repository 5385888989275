<template>
  <main v-element-sizes="{ id: 'window' }" class="layout-default">
    <SeoTemplatePreset>
      <LottieAnimation />
      <NavigationMenu />
      <slot />
      <FooterMain />

      <WidgetFullScreenVideoOverlay />
    </SeoTemplatePreset>
  </main>
</template>

<script setup></script>
